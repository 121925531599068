var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs6: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [_vm._v("Zelfopname")])
          ]),
          _c(
            "v-flex",
            { staticClass: "relative", attrs: { xs6: "", "text-right": "" } },
            [
              _c(
                "div",
                { staticClass: "elementTitleButton absolute" },
                [
                  _c(
                    "v-menu",
                    {
                      ref: "dialog",
                      attrs: {
                        "close-on-content-click": false,
                        lazy: "",
                        "nudge-right": "100",
                        "full-width": "",
                        "max-width": "290px",
                        "min-width": "290px"
                      }
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "input input-date",
                        attrs: {
                          slot: "activator",
                          placeholder: "DD-MM-YYYY",
                          label: "Datum",
                          "error-messages": _vm.date.errorMessage
                        },
                        slot: "activator",
                        model: {
                          value: _vm.date.formattedDate,
                          callback: function($$v) {
                            _vm.$set(_vm.date, "formattedDate", $$v)
                          },
                          expression: "date.formattedDate"
                        }
                      }),
                      _c("v-date-picker", {
                        ref: "picker",
                        attrs: {
                          "first-day-of-week": "1",
                          locale: "nl-nl",
                          min: "1910-01-01",
                          color: "#837f16"
                        },
                        on: {
                          change: function($event) {
                            _vm.fetchStatisticsDetail(),
                              _vm.fetchStatisticsRejectReasons(),
                              _vm.fetchStatisticsRemote()
                          }
                        },
                        model: {
                          value: _vm.date.selectedDate,
                          callback: function($$v) {
                            _vm.$set(_vm.date, "selectedDate", $$v)
                          },
                          expression: "date.selectedDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", md12: "" } },
            [
              _vm.$store.state.isServiceOrganization
                ? _c(
                    "div",
                    { staticClass: "elementPanel elementPanel--spaced" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { x12: "" } },
                            [
                              !_vm.isLoading
                                ? _c("WeekStatisticsView", {
                                    attrs: {
                                      items: _vm.planningStatistics,
                                      columns: _vm.columns
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
                _c("h2", { staticClass: "elementSubTitle" }, [
                  _vm._v("Opnames op Afstand")
                ])
              ]),
              _c(
                "div",
                { staticClass: "elementPanel elementPanel--spaced" },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { x12: "" } },
                        [
                          !_vm.isLoading
                            ? _c("WeekStatisticsView", {
                                attrs: {
                                  items: _vm.remoteStatistics,
                                  columns: _vm.columns
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
                    _c("h2", { staticClass: "elementSubTitle" }, [
                      _vm._v("Reden van wijzigingen naar Regulier")
                    ])
                  ]),
                  _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
                    _vm.$store.state.isServiceOrganization
                      ? _c(
                          "div",
                          { staticClass: "elementPanel elementPanel--spaced" },
                          [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { x12: "" } },
                                  [
                                    !_vm.isLoading
                                      ? _c("WeekStatisticsView", {
                                          attrs: {
                                            items: _vm.planningRejectReasons,
                                            columns: _vm.columns
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ],
                1
              ),
              _c("router-view")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }